import React, { Fragment } from 'react'
import _get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import { ChevronLeft } from 'react-feather'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image'
import './PressPost.css'
import Donate from '../components/Donate'

export const PressPostTemplate = ({
  title,
  date,
  contact,
  place,
  engdate,
  engtitle,
  engtext,
  spdate,
  sptitle,
  sptext,
  engfeaturedvideo,
  spfeaturedvideo,
  nextPostURL,
  prevPostURL,
  donatevalues
}) => (
  <main>
    <article
      className="PressPost section light"
      itemScope
      itemType="http://schema.org/BlogPosting"
    >
      <div className="container skinny">

        <div className="PressPost--Content relative">
          <div className="PressPost--Meta">
            {engdate && (
              <time
                className="PressPost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={engdate}
              >
                {engdate}
              </time>
            )}
          </div>
          {engtitle && (
                <h1 className="PressPost--Title" itemProp="title">
                  {engtitle}
                  </h1>
              )}
              {engfeaturedvideo && 
              <div className="PressPost--Video">
                <iframe width="560px" height="315px" 
                src={`https://www.youtube-nocookie.com/embed/${engfeaturedvideo}`}
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
                </div>}
                <div className="PressPost--InnerContent">
                  <Content source={`**${place}** -- ${engtext}`} />
                </div>
              <div className="PressPost--MetaSP">
            {spdate && (
              <time
                className="PressPost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={spdate}
              >
                {spdate}
              </time>
            )}
          </div>
              {sptitle && (
                <h1 className="PressPost--Title" itemProp="title">
                  {sptitle}
                  </h1>
              )}
              {spfeaturedvideo && 
              <div className="PressPost--Video">
              <iframe width="560px" height="315px" 
                src={`https://www.youtube-nocookie.com/embed/${spfeaturedvideo}`}
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
                </div>}
                <div className="PressPost--InnerContent">
                <Content source={`**${place}** -- ${sptext}`} />
                </div>
              <div className="PressPost--InnerContent">
                <br/>
              <b>Contact:</b> {contact}
              </div>
         
          <div className="PressPost--Pagination">
            {prevPostURL && (
              <Link
                className="PressPost--Pagination--Link prev"
                to={prevPostURL}
              >
                Previous Post
              </Link>
            )}
            {nextPostURL && (
              <Link
                className="PressPost--Pagination--Link next"
                to={nextPostURL}
              >
                Next Post
              </Link>
            )}
          </div>
        </div>
      </div>
    </article>
    <Donate items={donatevalues} />
  </main>
)

// Export Default PressPost for front-end
const PressPost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
    >
      <PressPostTemplate
        {...post}
        {...post.frontmatter}
        {...post.frontmatter.featuredImage}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
      />
    </Layout>
  )
}

export default PressPost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query PressPost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        contact
        place
        engdate
        engtitle
        engtext
        spdate
        sptitle
        sptext
        engfeaturedvideo
        spfeaturedvideo
        donatevalues
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "campaign-statements" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
